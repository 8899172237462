import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'

import * as actions from '../../../actions/clientAccounts'
import { GlobalState } from '../../../reducers'
import { dollarFormat, dateFormat } from '../../helpers'
import { rollupOriginalAllocations } from '../../helpers/clientAccounts'
import { ClientAccountObj } from '../../../objects/clientAccount'

import ActionDropdown from '../../components/actionDropdown'
import ToggleSwitch from '../../components/toggleSwitch'
import AllocationGraph from './allocationGraph'
import Tooltip from '../../components/tooltip'

import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg'
import overrideIcon from '../../assets/images/icons/png/ic_updates.png'
// import { ReactComponent as DeleteIcon } from '../../assets/images/icons/delete.svg';

interface ClientAccountRowProps {
  clientAccount: ClientAccountObj
  dispatch: Dispatch<GlobalState>
  householdFinId: string
}

interface ClientAccountRowState {
  showEditAccountModal: boolean
}

export class ClientAccountRow extends Component<
  ClientAccountRowProps,
  ClientAccountRowState
> {
  constructor(props: ClientAccountRowProps) {
    super(props)
    this.state = {
      showEditAccountModal: false
    }
  }

  public toggleEditModal = () => {
    this.setState({ showEditAccountModal: !this.state.showEditAccountModal })
  }

  public handlePssSelectedToggle = () => {
    const { clientAccount, householdFinId, dispatch } = this.props
    dispatch(
      actions.updateClientAccount(clientAccount.id, householdFinId, {
        pssSelected: !clientAccount.pssSelected
      })
    )
  }

  public editAccount = () => {
    return (
      <Link
        style={{ display: 'flex', alignItems: 'center' }}
        to={{
          pathname:
            '/households/' +
            this.props.householdFinId +
            '/clientAccount/' +
            this.props.clientAccount.id +
            '/investments'
        }}>
        <EditIcon />
        <span className='action-dropdown__item-label'>Edit Account</span>
      </Link>
    )
  }

  public attentionNeededWarning = () => {
    if (this.isErrorAccount()) {
      return (
        <Tooltip
          message={'Attention needed'}
          width={160}
          position='bottom'
          multiLine={true}
          style={{ left: '6px' }}>
          <div className='inv-accounts__error-icon' />
        </Tooltip>
      )
    } else {
      return null
    }
  }

  public isErrorAccount = () => {
    const { clientAccount } = this.props

    return (
      !Object.keys(clientAccount.discreteAllocation).length ||
      clientAccount.discreteAllocation.unclassified === 100 ||
      clientAccount.category === null
    )
  }

  public render() {
    const { clientAccount } = this.props
    rollupOriginalAllocations(clientAccount)
    const dropdownActions = [
      {
        element: this.editAccount(),
        onClick: this.toggleEditModal
      }
    ]

    const dollarsFormatted = () => {
      const liabilityDollarNames = [
        'Short-Term Liabilities',
        'Long-Term Liabilities'
      ]
      return dollarFormat(
        clientAccount.totalValue,
        0,
        clientAccount.category &&
          liabilityDollarNames.indexOf(clientAccount.category) >= 0
      )
    }
    return (
      <div className='inv-accounts inv-accounts__col-w'>
        <div className='inv-accounts__col-1'>
          <div className='inv-accounts__name'>
            <h4>{clientAccount.accountNickname}</h4>
            <div className='inv-accounts__registration-type-w'>
              {clientAccount.registrationType ? (
                <span className='inv-accounts__registration-type'>
                  {clientAccount.registrationType}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className='inv-accounts__col-2'>
          <div className='inv-accounts__classification'>
            {clientAccount.category}
          </div>
        </div>
        <div className='inv-accounts__col-3'>
          <div className='inv-accounts__strategy'>
            {clientAccount.productName}
          </div>
        </div>
        <div className='inv-accounts__col-4'>
          <div className='inv-accounts__allocation-graph-w'>
            <AllocationGraph
              equity={clientAccount.discreteAllocation.equity}
              fix={clientAccount.discreteAllocation.fixed}
              cash={clientAccount.discreteAllocation.cash}
              alt={clientAccount.discreteAllocation.alt}
              unclassified={clientAccount.discreteAllocation.unclassified}
            />
            {clientAccount.allocationOverridden ? (
              <Tooltip
                message='Account allocation has been overridden'
                width={120}
                position='bottom'
                multiLine={true}>
                <img
                  src={overrideIcon}
                  alt='Override Icon'
                  className='inv-accounts__override-icon'
                />
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className='inv-accounts__col-5'>
          <div className='inv-accounts__amount-w'>
            <div
              className={` ${
                this.isErrorAccount() && !clientAccount.balanceDate
                  ? 'inv-accounts__error'
                  : ''
              }`}>
              {dollarsFormatted()}
              {!clientAccount.balanceDate && this.attentionNeededWarning()}
            </div>
            <div
              className={`inv-accounts__amount-date ${
                this.isErrorAccount() && clientAccount.balanceDate
                  ? 'inv-accounts__error'
                  : ''
              }`}>
              {dateFormat(clientAccount.date)}
              {clientAccount.balanceDate && this.attentionNeededWarning()}
            </div>
          </div>
        </div>
        <div className='inv-accounts__col-6'>
          <ToggleSwitch
            active={clientAccount.pssSelected}
            handleToggleActive={this.handlePssSelectedToggle}
          />
        </div>
        <div className='inv-accounts__col-7'>
          <ActionDropdown left={false} actions={dropdownActions} />
        </div>
      </div>
    )
  }
}

export default connect()(ClientAccountRow)
