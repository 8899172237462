import React, { useState } from 'react'

import sortIconIdle from '../../../assets/images/strategySearch/ic-sort.png'
import sortIconDescending from '../../../assets/images/strategySearch/ic-sort-up.png'
import sortIconAscending from '../../../assets/images/strategySearch/ic-sort-down.png'

export interface ISortingArrowsProps {
  id: string
  displayName: string
  className: string
  option: string
  ascending: boolean
  sortFunc(e: React.MouseEvent<HTMLDivElement>): void
}

export const SortingArrows: React.FC<ISortingArrowsProps> = ({
  id,
  displayName,
  className,
  sortFunc,
  children,
  option,
  ascending
}) => {
  const [localId, setLocalId] = useState(id)

  const getArrows = () => {
    if (localId === option) {
      return ascending ? sortIconAscending : sortIconDescending
    }
    return sortIconIdle
  }

  const sortIcon = getArrows()

  return (
    <div className={className}>
      <div id={localId} onClick={sortFunc}>
        {displayName}
        {children}
        <img
          src={sortIcon}
          alt='Sort by Strategy Name'
          className='strategy-search__sort-arrow'
        />
      </div>
    </div>
  )
}

export default SortingArrows
