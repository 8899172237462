import React, { FC } from 'react'
import {
  IMStrategiesObj,
  CollateralObj,
  custodianKeyMap
} from '../../../../objects/strategies'
import { formatGoalAmount } from '../../../helpers'
import * as SortHelper from '../../../helpers/householdPreferences'
import { InstitutionObj } from '../../../../objects/institution'
import helpIcon from '../../../assets/images/strategySearch/ic-help.png'
import { ESTooltipType } from '../strategyTooltip'

interface IFeeDisplay {
  title: string
  fee: number
  isFinlifeInstitution?: boolean
  tooltip?: ESTooltipType
}

export const FeeDisplay = ({
  isFinlifeInstitution,
  fee,
  title,
  tooltip
}: IFeeDisplay) => {
  if (isFinlifeInstitution) return null

  return (
    <span>
      <h5>
        {title}
        {tooltip && (
          <img
            src={helpIcon}
            data-tip={tooltip}
            data-for={tooltip}
            className='strategy-tooltip__icon'
          />
        )}
      </h5>

      <span>
        {(fee > 0 && fee && formatGoalAmount(fee, 'percent')) || '0.00%'}
      </span>
    </span>
  )
}

export const FeesList: FC<{
  strategy: IMStrategiesObj
  institution: InstitutionObj
}> = (props) => {
  const { institution } = props
  const {
    expenseRatio = null,
    managerFee = null,
    operationalFee = null,
    yield: yieldDisplay = null
  } = props.strategy

  return (
    <div
      className='strategy-search__col-item strategy-search__col-item--long-med'
      style={{ width: '140px' }}>
      <div className='strategy-search__item strategy-search__item-list'>
        <FeeDisplay title='12-Month Yield' fee={yieldDisplay} />
        <FeeDisplay
          title='Expense Ratio'
          fee={expenseRatio}
          tooltip={ESTooltipType.expenseRatio}
        />
        <FeeDisplay
          title='Manager Fee'
          fee={managerFee}
          tooltip={ESTooltipType.managerFee}
          isFinlifeInstitution={institution.isFinlifeInstitution}
        />
        <FeeDisplay
          title='Operational Fee'
          fee={operationalFee}
          tooltip={ESTooltipType.operationalFee}
          isFinlifeInstitution={institution.isFinlifeInstitution}
        />
      </div>
    </div>
  )
}

export const CollateralFiles: FC<{
  collaterals: CollateralObj[]
  expand: boolean
}> = (props) => {
  const { collaterals, expand } = props

  const ranked: CollateralObj[] = []
  let unRanked: CollateralObj[] = []

  collaterals?.forEach((collateral: CollateralObj) => {
    const { rankOrder, name } = collateral
    if (!rankOrder || !name) {
      unRanked.push(collateral)
    } else {
      ranked.push(collateral)
    }
  })

  ranked.sort((a, b) => {
    const fieldName = 'rankOrder'
    return SortHelper.compareNumericField(a[fieldName], b[fieldName], false)
  })

  unRanked = unRanked.sort((a, b) => {
    const fieldName = 'name'
    if (!a[fieldName]) {
      return 1
    }
    return SortHelper.compareStringField(a[fieldName], b[fieldName], false)
  })

  const filesDisplay = [...ranked, ...unRanked].map(
    (collateral: CollateralObj) => {
      const openFileWindow = () => {
        collateral.collateralUrl &&
          window.open(`${collateral.collateralUrl}`, '_blank')
      }
      return (
        <span key={collateral.id} onClick={openFileWindow}>
          {`${collateral.name}`}
        </span>
      )
    }
  )

  const collateralsClass = expand
    ? 'strategy-search__item-collateral strategy-search__item-collateral--expand'
    : 'strategy-search__item-collateral'
  return (
    <div className='strategy-search__col-item strategy-search__col-item--long-med'>
      <div className='strategy-search__item strategy-search__item-list strategy-search__item-list--item'>
        <h5>Collateral</h5>
        <div className={`strategy-search__item-list ${collateralsClass}`}>
          {filesDisplay}
        </div>
        {props.children}
      </div>
    </div>
  )
}

export const CustodianList: FC<{
  strategy: IMStrategiesObj
}> = (props) => {
  const { strategy } = props
  // validate if all custodian flags are false for a given strategy
  const noDataAvailable = Object.values(custodianKeyMap).every(
    (custodian) => strategy[custodian] === false
  )

  if (noDataAvailable) return <div />

  return (
    <div className='strategy-search__item strategy-search__item-table'>
      <div className='strategy-search__item strategy-search__item-table strategy-search__item-table--row'>
        <div className='strategy-search__item-table--custodian'>
          <h5>{'Available Custodians'}</h5>
        </div>
      </div>

      {Object.keys(custodianKeyMap).map((custodianItem) => {
        return (
          (strategy[custodianKeyMap[custodianItem]] && (
            <div
              key={custodianKeyMap[custodianItem]}
              className='strategy-search__item strategy-search__item-table strategy-search__item-table--row  strategy-search__item-table--item'>
              <div className='strategy-search__item-table--custodian'>
                {custodianItem}
              </div>
            </div>
          )) ||
          null
        )
      })}
    </div>
  )
}
