import React from 'react'
import { ReactComponent as ChevronIcon } from '../../../assets/images/icons/arrow.svg'

interface ICollapseDynamicIconProps {
  open: boolean
  size?: number
  color?: React.CSSProperties['color']
  iconStyles?: React.CSSProperties
}

const CollapseDynamicIcon: React.FC<ICollapseDynamicIconProps> = ({
  open,
  size = 20,
  color = '#245eb9',
  iconStyles
}) => {
  const icnStyle = {
    ...iconStyles,
    transform: open ? 'rotate(180deg)' : 'rotate(90deg)'
  }

  return (
    <ChevronIcon
      color={color}
      height={size}
      width={size}
      style={icnStyle}
      stroke={color}
      strokeWidth={open ? '2' : '1'}
    />
  )
}

export default CollapseDynamicIcon
