import React, { Fragment } from 'react'
import ContentHeader from '../../components/layout/contentHeader'
import Tile from '../../components/layout/tile'
import BackButton from '../../components/backButton'
import Button from '../../components/button'
import { history } from '../../../store'
import AllocationDescription from './allocationDescription'
import * as actions from '../../../actions/investmentViewfinder'
import { Dispatch, connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import {
  InvestmentViewfinderAllocationInterface,
  InvestmentViewfinderInterface
} from '../../../objects/investmentViewfinder'

interface AllocationProps {
  householdFinId: string
  exerciseId: string
  exercises: {
    [householdId: string]: InvestmentViewfinderInterface
  }
  allocations: InvestmentViewfinderAllocationInterface
  dispatch: Dispatch<GlobalState>
}

interface AllocationState {
  active: string
  hovering: string
  showDisclosures: boolean
}

class TargetAllocation extends React.Component<
  AllocationProps,
  AllocationState
> {
  constructor(props: AllocationProps) {
    super(props)
    const { exercises, exerciseId, householdFinId } = this.props
    const selectedAllocationName =
      exercises[householdFinId][exerciseId].selectedAllocation
    this.state = {
      active: selectedAllocationName || null,
      hovering: null,
      showDisclosures: false
    }
  }
  public rightHeader = () => {
    const { active } = this.state
    return (
      <Button
        primary={true}
        onClick={this.navigateToResults}
        disabled={!active}>
        Next
      </Button>
    )
  }
  public navigateBack = () => {
    const { exerciseId, householdFinId } = this.props
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/priorities/geography`
    )
  }
  public navigateToResults = async () => {
    const { active } = this.state
    const { allocations, householdFinId, exerciseId } = this.props
    const activeAllocation = allocations[active]
    await this.props.dispatch(
      actions.updateViewfinderAllocation(
        householdFinId,
        exerciseId,
        active,
        activeAllocation.stocks,
        activeAllocation.bonds
      )
    )
    history.push(
      `/households/${householdFinId}/investmentViewfinder/${exerciseId}/results`
    )
  }
  public setActive = (name: string) => {
    this.setState({ active: name })
  }
  public onHover = (name: string) => {
    this.setState({ hovering: name })
  }
  public allocationList = () => {
    const { allocations } = this.props
    const { hovering, active } = this.state
    return Object.keys(allocations).map((allocationName) => {
      const allocation = allocations[allocationName]
      return (
        <AllocationDescription
          key={allocationName}
          name={allocationName}
          allocation={allocation}
          checked={active === allocationName}
          hovering={allocationName === hovering}
          opaque={
            (hovering && allocationName !== hovering) ||
            (active && allocationName !== active)
          }
          onHover={this.onHover}
          onClick={this.setActive}
        />
      )
    })
  }
  public toggleDisclosures = () => {
    this.setState({ showDisclosures: !this.state.showDisclosures })
  }
  public disclosures = () => {
    return (
      <div className='ivf-target-allocation__disclosure'>
        <div className='ivf-target-allocation__disclosure-definitions'>
          <hr />
          <p>
            Definitions of the allocation classes come from each index’s fact
            sheets. Past performance may not be indicative of future results.
            This is for illustrative purposes only and does not represent
            performance of any particular allocation. It is not possible to
            invest directly in an index.
          </p>
          <p>
            <b>Fixed Income</b> is represented by Barclays US Aggregate Bond
            Index, an unmanaged market-weighted index that consists of
            investment grade corporate bonds, mortgages and U.S. Treasury and
            government agency issues with at least one year to maturity.
          </p>
          <p>
            <b>US Equities</b> is represented by Russell 3000 Index measures the
            performance of the largest 3,000 US companies designed to represent
            approximately 98% of the investible US equity market. The Index is
            reconstructed annually.
          </p>
          <p>
            <b>Non-US Equities</b> is represented by MSCI All Country World
            Index ex-USA, captures large and mid-cap representation across 23
            developed market countries and emerging market countries. The index
            covers approximately 85% of the global equity opportunity set
            outside the US.
          </p>
        </div>
      </div>
    )
  }
  public render() {
    const { showDisclosures } = this.state
    return (
      <Fragment>
        <ContentHeader title='Choose Allocation' />
        <Tile
          leftHeader={<BackButton onClick={this.navigateBack} />}
          rightHeader={this.rightHeader()}
          headerBorder={true}
          headerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#FAFAFA'
          }}>
          <div className='ivf-target-allocation-w'>{this.allocationList()}</div>
        </Tile>
        <Tile style={{ marginTop: 10 }}>
          <div
            className='ivf-target-allocation__disclosure-w'
            onClick={this.toggleDisclosures}>
            Data is as of 12/31/1999 through 9/25/2024. Benchmarks used are
            Barclays US Aggregate for representative fixed income, Russell 3000
            for US Equities and MSCI ACWI ex US for Non-US Equities. Stocks here
            are represented by 75% Russell 3000 and 25% MSCI ACWI ex US. Data
            also assumes daily end of day values.
            <div className='ivf-target-allocation__disclosure-callout'>
              <b>Important Information</b> (click on text-link to open new view
              with Definitions)
            </div>
            {showDisclosures ? this.disclosures() : null}
          </div>
        </Tile>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    exercises: store.investmentViewfinder.exercises,
    allocations: store.investmentViewfinder.allocations
  }
}

export default connect(mapStateToProps)(TargetAllocation)
