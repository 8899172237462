import {
  IMStrategiesObj,
  IMClassifiedStrategiesInterface
} from '../../objects/strategies'

import {
  accountMinimumMap,
  StrategySearchFilterObj
} from '../../objects/strategySearch'

import * as SortHelper from './householdPreferences'

/**
 *
 * @param numberValue number
 * @param rangeKeys array of keys
 * @return boolean
 *
 */
const withinRange = (numberValue: number, rangeKeys: string[]): boolean => {
  return rangeKeys.some((rangeKey: string) => {
    return (
      accountMinimumMap[rangeKey] &&
      accountMinimumMap[rangeKey].min <= numberValue &&
      numberValue <= accountMinimumMap[rangeKey].max
    )
  })
}
/**
 * OR within filter group
 * AND across filters
 * @param strategySearch StrategySearchState
 * @return strategy[]
 * used to filter strategies by certain fields
 * after v2 no longer used but saved in case needed down the line
 */
export const filterStrategyState = (
  strategies: IMStrategiesObj[],
  filter: StrategySearchFilterObj
) => {
  const someExist = (
    argParam: string | number | string[],
    filterFor: string[]
  ): boolean => {
    switch (typeof argParam) {
      case 'string':
        return filterFor.some((arg) => {
          return arg === argParam
        })
      case 'number':
        return withinRange(argParam, filterFor)
      case 'object':
        return argParam.some((arg) => {
          return filterFor.includes(arg)
        })
      case 'boolean':
        return filterFor.some((arg) => {
          return arg === argParam
        })
      default:
        return false
    }
  }

  return strategies.filter((strategy: IMStrategiesObj) => {
    const filterCriteria: boolean[] = []
    Object.keys(filter)
      .filter((filterName: string) => {
        return filter[filterName]?.length
      })
      .forEach((criteria: string) => {
        filterCriteria.push(
          // true | false are valid filter values for esg filter(reason for specific null/undefined check)
          strategy[criteria] !== null &&
            strategy[criteria] !== undefined &&
            someExist(strategy[criteria], filter[criteria])
        )
      })

    return filterCriteria.every((isTrue: boolean) => isTrue)
  })
}

/**
 *
 * @param searchBarValue
 * string
 * @param strategies
 * IMStragiesObj[]
 * ?= (Positive Lookahead, google it)
 */
export const searchByName = (
  searchBarValue: string,
  strategies: IMStrategiesObj[]
) => {
  if (!searchBarValue.trim().length) return strategies
  return strategies.filter((strategy: IMStrategiesObj) => {
    const { name } = strategy
    const inputPattern = searchBarValue
      .trim()
      .replace(/(?=[^a-zA-Z0-9])/g, '\\')
    return name.match(RegExp(inputPattern, 'gi'))
  })
}

/**
 * sorting
 */
export const sortStrategies = (
  strategies: IMStrategiesObj[],
  sortBy: string,
  ascending: boolean
): IMStrategiesObj[] => {
  if (!strategies?.length) {
    return null
  }
  return strategies.sort((a, b) => {
    switch (sortBy) {
      case 'name':
      case 'assetClassLevelFive':
        return SortHelper.compareStringField(a[sortBy], b[sortBy], ascending)
      default:
        return SortHelper.compareNumericField(a[sortBy], b[sortBy], ascending)
    }
  })
}

export const astClsL2Order = { 'United Capital': 1 }

export const orderAssetClasses = (
  a: string,
  b: string,
  order: Record<string, number>
) => {
  const idxA = order[a] || Number.MAX_SAFE_INTEGER
  const idxB = order[b] || Number.MAX_SAFE_INTEGER
  if (idxA < idxB) return -1
  if (idxB > idxB) return 1
  return 0
}
