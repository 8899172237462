import * as actions from '../actions/strategySearch'
import {
  StrategySearchState,
  custodiansFilterKeys
} from '../objects/strategySearch'
import { IMStrategiesObj, CollateralObj } from '../objects/strategies'

export const initState: StrategySearchState = {
  loading: false,
  search: '',
  strategies: []
}

const mapStrategyCollaterals = (collaterals: CollateralObj[]) => {
  return (
    (collaterals &&
      collaterals.map((collateral: CollateralObj) => {
        const {
          id,
          strategyId,
          name,
          filename,
          url,
          collateralUrl,
          rankOrder
        } = collateral
        return {
          id,
          strategyId,
          name: name || undefined,
          filename,
          url,
          collateralUrl,
          rankOrder
        }
      })) ||
    null
  )
}

const mapStrategies = (responseArray: IMStrategiesObj[]): IMStrategiesObj[] => {
  const assignedCustodian = (strategyObj: IMStrategiesObj) => {
    return Object.keys(custodiansFilterKeys).filter((custodian) => {
      if (strategyObj[custodiansFilterKeys[custodian]]) {
        return custodiansFilterKeys[custodian]
      }
    })
  }

  return responseArray.map((strategyObj: IMStrategiesObj) => {
    const custodianArray = assignedCustodian(strategyObj)
    const collateralArray = mapStrategyCollaterals(strategyObj.collateral)
    /**
     * account type is saved as ';' seperated values which is not a valid filter type
     * filter requires an array
     */

    const taxStatus = strategyObj.taxStatus?.split(';')

    return {
      ...strategyObj,
      taxStatus,

      collateral: collateralArray,
      custodianSelection: custodianArray
    }
  })
}

const StrategySearch = (
  state = initState,
  action: any
): StrategySearchState => {
  switch (action.type) {
    case `${actions.GET_IM_STRATEGIES}_PENDING`:
      return { ...state, loading: true }
    case `${actions.GET_IM_STRATEGIES}_FULFILLED`:
      const responseArray = action.payload?.data || []
      const mappedStrategies = mapStrategies(responseArray)
      return {
        ...state,
        strategies: mappedStrategies,
        loading: false
      }
    case actions.UPDATE_SEARCH_TEXT:
      return { ...state, search: action.searchText }
    default:
      return state
  }
}

export default StrategySearch
