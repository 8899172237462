import React, { Component, FormEvent, ChangeEvent } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import CustomSelect from '../../components/CustomSelect'
import Input from '../../components/Input'
import TextArea from '../../components/TextArea'

import {
  dateFormat,
  numberFormat,
  formatCurrencyInput,
  sanitizeCurrency
} from '../../helpers'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { mapOwnerIds } from '../../helpers/netWorth'
import { HouseholdObj } from '../../../objects/household'
import {
  taxStatusList,
  classificationOptionList
} from '../../../objects/clientAccount'
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg'
import Button from '../../components/button'

export interface AccountInformationTileProps {
  dispatch: Dispatch<GlobalState>
  household: HouseholdObj
  householdFinId: string
  accountNumber: string
  accountNickname: string
  ownerIds: string[]
  accountType: string
  registrationType: string
  taxStatus: string
  guidebookClassification: string
  totalValueManual: number
  costBasis: number
  balanceDate: string
  description: string
  source: string
  errors: string[]
  requiredFields: string[]
  inputSetter(fieldName: string, fieldValue: string | number | boolean): void
}

interface AccountInformationTileState {
  ownerDisplayName: string
  accountNickname: string
  accountNumber: string
  accountType: string
  description: string
  registrationType: string
  balance: string
  balanceDate: Date
  requiredFields: string[]
}

const nonManualEditableInputs: string[] = [
  'accountNickname',
  'taxStatus',
  'description',
  'ownerIds',
  'guidebookClassification',
  'registrationType'
]
const inputFields = {
  accountNumber: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid account number.',
    nonManual: false
  },
  accountNickname: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid account name.',
    nonManual: true
  },
  accountType: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid account type.',
    nonManual: false
  },
  registrationType: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid registration type.',
    nonManual: true
  },
  guidebookClassification: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Select a valid classifcation.',
    nonManual: true
  },
  totalValueManual: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Enter a valid account balance.',
    nonManual: false
  },
  taxStatus: {
    errorStyle: 'form-input-Error',
    errorMsg: 'Select a status',
    nonManual: true
  },
  description: { errorStyle: 'form-input-Error', errorMsg: '', nonManual: true }
}

export class AccountInformationTile extends Component<
  AccountInformationTileProps,
  AccountInformationTileState
> {
  constructor(props: AccountInformationTileProps) {
    super(props)
    this.state = {
      description: '',
      ownerDisplayName: '',
      accountNickname: '',
      accountType: '',
      accountNumber: '',
      registrationType: '',
      balance: '',
      balanceDate: moment().toDate(),
      requiredFields: []
    }
  }

  public componentDidUpdate(nextProps: any, prevProps: any) {
    const prevPropsKeys = Object.keys(prevProps)
    const nextPropsKeys = Object.keys(nextProps)
    if (prevPropsKeys.length < nextPropsKeys.length) {
      this.updatedClientAccountProps(nextProps)
    }
  }

  public updatedClientAccountProps = (nextProps: any) => {
    const { balanceDate, ...otherProps } = nextProps
    this.setState({ ...otherProps })
    this.setState({
      balanceDate: moment(nextProps.balanceDate).toDate()
    })

    const { ownerIds, household } = nextProps
    const ownersList = mapOwnerIds(household)
    let ownerSelected = ''
    Object.keys(ownersList).filter((owner) => {
      if (nextProps.ownerIds.length === 2) {
        ownerSelected = 'Joint'
      } else {
        if (ownerIds[0] === household.primaryContact) {
          ownerSelected = 'Primary'
        }
        if (ownerIds[0] === household.secondaryContact) {
          ownerSelected = 'Secondary'
        }
      }
    })

    this.setState({
      ownerDisplayName: ownerSelected
    })
  }

  public inputChanged = (fieldName: string): boolean => {
    const propFieldName = this.props[fieldName]
    const stateFieldName = this.state[fieldName]
    if (propFieldName === null || propFieldName === undefined) {
      return false
    }
    return propFieldName !== stateFieldName
  }

  public setDate = (date: Date) => {
    this.setState({
      balanceDate: date
    })
    this.props.inputSetter('balanceDate', dateFormat(date))
  }

  public selectOwner = (selection: any) => {
    this.setState({ ownerDisplayName: selection.label })

    const ownerIdArray = selection.extValue.split(',')
    if (ownerIdArray.length === 1) {
      ownerIdArray.push(null)
    }

    this.props.inputSetter('ownerIds', ownerIdArray)
  }

  public updateTaxStatus = (selection: any) => {
    this.props.inputSetter('taxStatus', selection.value)
  }

  public updateGuidebookClassification = (selection: any) => {
    this.props.inputSetter('guidebookClassification', selection.value)
  }

  public textareaSetter = (e: any) => {
    const { name, value } = e.currentTarget
    this.props.inputSetter(name, value)
  }

  public inputSetter = (isDollar?: boolean) => (
    e: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget
    let newValue: string | number = value
    if (name === 'totalValueManual') {
      newValue = sanitizeCurrency(newValue)
      if (newValue === null) {
        newValue = 0
      }
    }
    this.props.inputSetter(name, newValue)
  }

  public isSourceEditable = (fieldName: string) => {
    const { source } = this.props
    if (source === null) {
      return true
    }
    if (nonManualEditableInputs.indexOf(fieldName) >= 0) {
      return true
    }
    if (
      source &&
      source
        .replace(/-|\s/g, '')
        .toUpperCase()
        .indexOf('MANUAL') >= 0
    ) {
      return true
    }
    return false
  }

  public stopProp = (e: any) => {
    e.stopPropagation()
  }

  public datePicker = () => {
    const { balanceDate } = this.state
    if (this.isSourceEditable('balanceDate') === true) {
      return (
        <div className='form-group'>
          <label className='form-label'>Balance Date</label>
          <div className='client-account-details__datepicker-container'>
            <div
              className={
                moment(balanceDate).format('YYYY-MM-DD') !==
                this.props.balanceDate
                  ? 'client-account-details__datepicker client-account-details__datepicker-changed'
                  : 'client-account-details__datepicker'
              }>
              <span
                onClick={this.stopProp}
                className='client-account-details__datepicker--active'>
                <DatePicker
                  selected={moment(balanceDate).isValid() ? balanceDate : null}
                  onChange={this.setDate}
                />
              </span>
              <Calendar />
            </div>
            <Button header onClick={() => this.setDate(moment().toDate())}>
              Today
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className={'form-group client-account-details__inputs-readonly'}>
          <Input
            title={'Balance Date'}
            inputType='text'
            name={'balanceDate'}
            controlFunc={null}
            content={
              moment(balanceDate).isValid()
                ? dateFormat(moment(balanceDate)?.toString())
                : null
            }
            readOnly={true}
          />
        </div>
      )
    }
  }

  public inputFields = (
    titleName: string,
    inputFieldName: string,
    contents: any,
    isDollar?: boolean
  ) => {
    const { requiredFields } = this.state
    const isReadOnly: boolean = this.isSourceEditable(inputFieldName)
    const dollarCss = isDollar ? 'client-account-details__inputs-money' : ''
    const mandatory: boolean = requiredFields.indexOf(inputFieldName) >= 0

    const errorState = () => {
      if (inputFieldName === 'totalValueManual') {
        return ''
      }
      return this.props.errors.indexOf(inputFieldName) >= 0
        ? inputFields[inputFieldName]
        : null
    }
    const errorMessage = () => {
      if (inputFieldName === 'totalValueManual') {
        return ''
      }
      return this.props.errors.indexOf(inputFieldName) >= 0
        ? 'Enter a ' + titleName
        : ''
    }

    return (
      <div
        className={
          isReadOnly === true
            ? 'form-group ' + dollarCss
            : 'form-group client-account-details__inputs-readonly ' + dollarCss
        }>
        <Input
          mandatory={mandatory}
          classNames={errorState() !== null ? errorState().errorStyle : ''}
          error={errorMessage()}
          isChanged={this.inputChanged(inputFieldName)}
          title={titleName}
          inputType='text'
          name={inputFieldName}
          controlFunc={this.inputSetter(isDollar)}
          content={contents}
          readOnly={!isReadOnly}
        />
      </div>
    )
  }

  public selectFields = (
    titleName: string,
    inputFieldName: string,
    contents: any,
    dataList: any,
    controlFunc: any
  ) => {
    const { requiredFields } = this.state
    const errorMsg = (value: string, isRequired: boolean): string => {
      return !value && isRequired ? inputFields[inputFieldName].errorMsg : ''
    }

    return (
      <CustomSelect
        classes='client-account-details__inputs client-account-details__inputs-custom-select'
        titleName={titleName}
        contents={contents}
        dataList={dataList}
        controlFunc={controlFunc}
        requiredField={requiredFields.indexOf(inputFieldName) >= 0}
        selectChanged={this.inputChanged(inputFieldName)}
        inputFieldName={inputFieldName}
        errorMsg={errorMsg(
          contents,
          requiredFields.indexOf(inputFieldName) >= 0
        )}
        toolTipText={
          requiredFields.indexOf(inputFieldName) >= 0
            ? 'This is a mandatory field'
            : ''
        }
        isDisabled={
          !this.isSourceEditable(inputFieldName) ||
          !dataList ||
          dataList.length <= 1
        }
      />
    )
  }

  public textareaField = (inputFieldName: string, text: string) => {
    return (
      <TextArea
        isChanged={this.inputChanged(inputFieldName)}
        name='description'
        value={text}
        controlFunc={this.textareaSetter}
        placeholder={text}
      />
    )
  }

  public renderRowOne = () => {
    const {
      household,
      accountNickname,
      accountNumber,
      accountType
    } = this.props
    const { ownerDisplayName } = this.state
    const accountNicknameDisplay = accountNickname || ''
    const accountNumberDisplay = accountNumber || ''
    const accountTypeDisplay = accountType || ''
    const ownersList = mapOwnerIds(household)

    return (
      <div className='form-inline client-account-details__inputs'>
        {this.inputFields(
          'Account Number',
          'accountNumber',
          accountNumberDisplay
        )}
        {this.inputFields(
          'Account Name',
          'accountNickname',
          accountNicknameDisplay
        )}
        {this.selectFields(
          'Owner',
          'ownerIds',
          ownerDisplayName,
          ownersList,
          this.selectOwner
        )}
        {this.inputFields('Account Type', 'accountType', accountTypeDisplay)}
      </div>
    )
  }

  public render() {
    const {
      registrationType,
      taxStatus,
      costBasis,
      totalValueManual,
      description
    } = this.props
    let { guidebookClassification } = this.props
    const balance = formatCurrencyInput(String(totalValueManual))

    switch (guidebookClassification) {
      case 'Other - Personal Assets':
        guidebookClassification = 'Personal Assets'
        break
      case 'Other - Business Assets':
        guidebookClassification = 'Business Assets'
        break
      default:
    }

    return (
      <div className='client-account-details'>
        <span />
        {this.renderRowOne()}
        <div className='form-inline client-account-details__inputs'>
          {this.inputFields(
            'Registration Type',
            'registrationType',
            registrationType
          )}
          {this.selectFields(
            'Tax Status',
            'taxStatus',
            taxStatus,
            taxStatusList,
            this.updateTaxStatus
          )}
          {this.selectFields(
            'Classification',
            'guidebookClassification',
            guidebookClassification,
            classificationOptionList,
            this.updateGuidebookClassification
          )}
          <div className='form-group' />
        </div>
        <div className='form-inline client-account-details__inputs'>
          {this.inputFields('Balance', 'totalValueManual', balance, true)}
          {this.datePicker()}
          <div className='form-group client-account-details__inputs-readonly client-account-details__inputs-money'>
            <Input
              title='Cost Basis'
              inputType='text'
              name='costBasis'
              controlFunc={null}
              content={numberFormat(costBasis, 0)}
              dollarSign={Boolean(
                costBasis === null || costBasis === undefined
              )}
              readOnly={true}
            />
          </div>
          <div className='form-group' />
        </div>
        <div className='form-inline client-account-details__inputs-desc'>
          <div>
            <label>Description</label>
            {this.textareaField('description', description)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { householdFinId }: any) => {
  return {
    household: store.households[householdFinId],
    householdFinId
  }
}

export default connect(mapStateToProps)(AccountInformationTile)
