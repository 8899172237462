import { createDeepEqualSelector } from './selectorCreator'
import {
  IMStrategiesObj,
  IBatchedStrategies,
  EAssetClsFld
} from '../../objects/strategies'
import { GlobalState } from '../../reducers'
import { merge } from 'lodash'

const searchRegEx = /(?=[^a-zA-Z0-9\s])/g

const generateBucketPath = (...args: Array<string | number>) => {
  const bucket = {}
  args.reduce((branch, asset) => {
    return (branch[asset] = {})
  }, bucket)

  return bucket
}
/**
 * qualify strategy for batching
 * based on assetClasses field values and search value if present
 */
const qualifyStrategy = (strategy: IMStrategiesObj, inputPattern: RegExp) => {
  if (
    strategy[EAssetClsFld.L1] &&
    strategy[EAssetClsFld.L2] &&
    strategy[EAssetClsFld.L3] &&
    strategy[EAssetClsFld.L4]
  ) {
    return !inputPattern || inputPattern.test(strategy.name)
  } else return false
}

export const getStrategies = (store: GlobalState) => {
  return store.strategySearch.strategies
}

export const getStrategySearchTerm = (store: GlobalState) => {
  return store.strategySearch.search
}

export const getStrategiesSelector = createDeepEqualSelector(
  [getStrategies, getStrategySearchTerm],
  (strategies, searchTerm): [IBatchedStrategies, string[], string[]] => {
    // sanitize search input and and convert to regular expression to test against strat name
    const inputPattern = RegExp(
      searchTerm
        .trim()
        .replace(searchRegEx, '\\')
        .replace(/\s/g, '.*'),
      'gi'
    )
    // strategy ids from selector is used to update/rebuild dynamic elements
    const strategyIds: string[] = []
    // unique asset class string chains so level specific controls dont conflict even with same name
    const assetClasses: Set<string> = new Set()
    // batched strategies returned after selector execution
    const batchedStrategies: any = {}

    for (let i = 0; i < strategies.length; i++) {
      if (qualifyStrategy(strategies[i], inputPattern)) {
        strategyIds.push(strategies[i].id)

        const {
          assetClassLevelOne: astClsL1,
          assetClassLevelTwo: astClsL2,
          assetClassLevelThree: astClsL3,
          assetClassLevelFour: astClsL4
        } = strategies[i]

        const bucketPath = generateBucketPath(astClsL1, astClsL2, astClsL3)
        merge(batchedStrategies, bucketPath)

        const lvl3Address = batchedStrategies[astClsL1][astClsL2][astClsL3]
        lvl3Address[astClsL4] = lvl3Address[astClsL4] || []
        lvl3Address[astClsL4].push(strategies[i])

        assetClasses
          .add(astClsL1 + astClsL2)
          .add(astClsL1 + astClsL2 + astClsL3)
          .add(astClsL1 + astClsL2 + astClsL3 + astClsL4)
      }
    }
    // generate array from set for easy component use
    const assetClassesArray = Array.from(assetClasses)

    return [batchedStrategies, assetClassesArray, strategyIds]
  }
)
