import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'

import StrategySortOption from '../strategyComponents/SortingArrows'

import { ESTooltipType } from '../strategyTooltip'
import helpIcon from '../../../assets/images/strategySearch/ic-help.png'

import { InstitutionObj } from '../../../../objects/institution'

export interface StrategySortingBarProps {
  sortBy: string
  ascending: boolean
  institution: InstitutionObj
  sortFunc(keyName: string, ascending: boolean): void
}

export class StrategySortingBar extends Component<StrategySortingBarProps> {
  public sortBy = (e: React.MouseEvent<HTMLDivElement>) => {
    const { sortBy, ascending } = this.props
    const { currentTarget } = e
    this.props.sortFunc(
      currentTarget.id,
      sortBy === currentTarget.id ? !ascending : true
    )
  }

  public flpDisplay = () => {
    const { sortBy, ascending, institution } = this.props

    const isFinlifeInstitution = institution && institution.isFinlifeInstitution

    const cssClass = isFinlifeInstitution
      ? 'strategy-search__col-item--flp-med'
      : 'strategy-search__col-item--short'

    const displayName = isFinlifeInstitution ? 'Supplemental Fees' : 'Fees'

    return (
      <StrategySortOption
        id='fees'
        displayName={displayName}
        className={`strategy-search__col-item ${cssClass} strategy-search__sort-item`}
        option={sortBy}
        ascending={ascending}
        sortFunc={this.sortBy}>
        {!isFinlifeInstitution ? (
          <img
            src={helpIcon}
            className='strategy-tooltip__icon strategy-search__sort-help'
            data-tip={ESTooltipType.sortFees}
            data-for={ESTooltipType.sortFees}
          />
        ) : null}
      </StrategySortOption>
    )
  }

  public render = () => {
    const { sortBy, ascending } = this.props

    return (
      <div
        className={`strategy-search__row strategy-search__sort strategy-search__sort-spacer`}>
        <StrategySortOption
          id='name'
          displayName='Strategy Name'
          className='strategy-search__col-item strategy-search__col-item--long strategy-search__sort-item'
          option={sortBy}
          ascending={ascending}
          sortFunc={this.sortBy}
        />
        <StrategySortOption
          id='assetClassLevelFive'
          displayName='Asset Type'
          className='strategy-search__col-item strategy-search__col-item--medium strategy-search__sort-item'
          option={sortBy}
          ascending={ascending}
          sortFunc={this.sortBy}
        />
        <StrategySortOption
          id='investmentMinimum'
          displayName='Account Min'
          className='strategy-search__col-item strategy-search__col-item--medium strategy-search__sort-item'
          option={sortBy}
          ascending={ascending}
          sortFunc={this.sortBy}
        />

        {this.flpDisplay()}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    institution: store.institution
  }
}

export default connect(mapStateToProps)(StrategySortingBar)
