import React, { Component, CSSProperties } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'

import StrategyDetails from './strategyDetails'
import { FeesList, CollateralFiles, CustodianList } from './strategyItemDisplay'
import { IMStrategiesObj } from '../../../../objects/strategies'
import { InstitutionObj } from '../../../../objects/institution'

import arrowIconDown from '../../../assets/images/strategySearch/ic-arrow-dropdown.png'

export interface StrategyItemProps {
  expandedId: string
  isLast: boolean
  strategy: IMStrategiesObj
  institution: InstitutionObj
  expandFunc(id: string): void
}

interface StrategyItemState {
  showCollaterals: boolean
}

export class StrategyItem extends Component<
  StrategyItemProps,
  StrategyItemState
> {
  constructor(props: StrategyItemProps) {
    super(props)
    this.state = {
      showCollaterals: false
    }
  }

  public expandFunc = () => {
    const { expandFunc, strategy } = this.props
    expandFunc(strategy.id)
  }

  public showMoreCollaterals = () => {
    this.setState({
      showCollaterals: !this.state.showCollaterals
    })
  }

  public renderShowMore = () => {
    const { collateral } = this.props.strategy
    const { showCollaterals } = this.state
    const arrowStyle = !showCollaterals
      ? 'strategy-search__item-expandIcon'
      : 'strategy-search__item-expandIcon strategy-search__item-expandIcon--rotate'

    return collateral?.length > 5 ? (
      <span onClick={this.showMoreCollaterals}>
        Show {showCollaterals ? 'Less' : 'More'}
        <img src={arrowIconDown} alt='Expand details' className={arrowStyle} />
      </span>
    ) : null
  }

  public render = () => {
    const { strategy, expandedId, institution, isLast } = this.props
    const { showCollaterals } = this.state
    const detailStyle: CSSProperties =
      !isLast || strategy.id === expandedId
        ? { borderBottom: '1px solid fafafa' }
        : null

    let columnExpandStyle = ''
    let additionalStyle = ''
    if (strategy.id === expandedId) {
      columnExpandStyle = 'strategy-search__item-col--expand'
      additionalStyle = 'strategy-search__item-additional--expand'
    }

    return (
      <div
        className={`strategy-search__item strategy-search__item--row strategy-search__item-col ${columnExpandStyle}`}
        style={detailStyle}>
        <div
          className={`strategy-search__item strategy-search__item-details`}
          onClick={this.expandFunc}>
          <StrategyDetails
            expand={strategy.id === expandedId}
            strategy={strategy}
          />
        </div>
        <div
          className={`strategy-search__item strategy-search__item-additional ${additionalStyle}`}>
          <FeesList strategy={strategy} institution={institution} />
          {strategy.collateral.length > 0 && (
            <CollateralFiles
              collaterals={strategy.collateral}
              expand={showCollaterals}>
              {this.renderShowMore()}
            </CollateralFiles>
          )}
          <div className='strategy-search__col-item strategy-search__col-item--long-med strategy-search__item-custodian'>
            <CustodianList strategy={strategy} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    institution: store.institution
  }
}

export default connect(mapStateToProps)(StrategyItem)
