import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../reducers'
import ActionLoader from '../../components/actionLoader'
import { withRouter } from 'react-router-dom'
import InnerContainer from '../../components/layout/innerContainer'
import { StrategyDisclaimer } from './strategyComponents'
import StrategyHeader from './strategyHeader'
import StrategyTile from './strategyTile'
import StrategyTable from './strategyTable'
import { getIMStrategies } from '../../../actions/strategySearch'
import { getInstitution } from '../../../actions/institution'
import { getStrategiesSelector } from '../../../selectors/v3/strategySearch'
import StrategyToolTip from './strategyTooltip'
import Button from '../../components/button'
import { ESTooltipType } from './strategyTooltip'
import { orderAssetClasses, astClsL2Order } from '../../helpers/strategies'

export type TStrategySearchProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export interface IStrategySearchState {
  [key: string]: boolean
}

export class StrategySearch extends Component<
  TStrategySearchProps,
  IStrategySearchState
> {
  constructor(props: TStrategySearchProps) {
    super(props)
    this.state = {}
  }

  public componentDidMount() {
    const { dGetIMStrategies, dGetInstitutions } = this.props
    dGetIMStrategies()
    // institusion required by child components, see StrategyItem and SortingBar
    dGetInstitutions()
    document.body.classList.add('strategy-search__body-styles')
  }

  public componentDidUpdate(prevProps: Readonly<TStrategySearchProps>): void {
    const { searchBarValue: prevSearchBarValue } = prevProps
    const { searchBarValue, strategyIds } = this.props

    if (
      prevSearchBarValue !== searchBarValue &&
      strategyIds.length &&
      searchBarValue.length === 3
    ) {
      this.handleExpand('expandAll')
    }
  }

  public componentWillUnmount() {
    document.body.classList.remove('strategy-search__body-styles')
  }

  public handleExpand = (
    mode:
      | 'expandAll'
      | 'collapseAll'
      | 'expandPartial'
      | 'collapsePartial'
      | 'toggleSingle',
    assetChain?: string
  ) => {
    const { assetClasses } = this.props

    switch (mode) {
      case 'expandAll':
        assetClasses.forEach((assetClass) => {
          this.setState({ [assetClass]: true })
        })
        break
      case 'collapseAll':
        assetClasses.forEach((assetClass) => {
          this.setState({ [assetClass]: false })
        })
        break
      case 'expandPartial':
        assetClasses.forEach((assetClass) => {
          if (assetClass.includes(assetChain)) {
            this.setState({ [assetClass]: true })
          }
        })
        break

      case 'collapsePartial':
        assetClasses.forEach((assetClass) => {
          if (assetClass.includes(assetChain)) {
            this.setState({ [assetClass]: false })
          }
        })
        break

      default:
        this.setState((prevState) => ({
          [assetChain]: !prevState[assetChain]
        }))
    }
  }

  public renderNullState = () => (
    <div className='strategy-search__item strategy-search__item-null--container strategy-search__item-null'>
      <span className='strategy-search__item-null strategy-search__item-null--title'>
        No Results
      </span>
      <span className='strategy-search__item-null strategy-search__item-null--subtitle'>
        No results match search expression. Try different one.
      </span>
    </div>
  )

  public renderPartialExpand = (identifier: string) => {
    const isOpen = Boolean(this.state[identifier])
    return (
      <div
        data-tip={ESTooltipType.showAll}
        data-for={ESTooltipType.showAll}
        style={{ marginLeft: 'auto' }}>
        <Button
          style={{ padding: '4px 8px', width: 124 }}
          primary
          onClick={(e) => {
            e?.stopPropagation()
            this.handleExpand(
              isOpen ? 'collapsePartial' : 'expandPartial',
              identifier
            )
          }}>
          {isOpen ? 'Hide Strategies' : 'View Strategies'}
        </Button>
      </div>
    )
  }

  public render() {
    const { batchedStrategies, loading, strategyIds } = this.props
    const lvl1AssetCls = Object.keys(batchedStrategies)
    return (
      <div className='strategy-search__main-w'>
        <StrategyHeader handleExpandAll={this.handleExpand} />
        <InnerContainer>
          {loading && !strategyIds.length && <ActionLoader />}
          {!loading && !strategyIds.length && this.renderNullState()}

          {lvl1AssetCls.map((assetClassL1) => {
            const lvl2AssetCls = Object.keys(
              batchedStrategies[assetClassL1]
            ).sort((a, b) => orderAssetClasses(a, b, astClsL2Order))

            return (
              <div key={assetClassL1} className='strategy-search__container'>
                <h2 className='strategy-search__h2'>{assetClassL1}</h2>
                {lvl2AssetCls.map((assetClassL2) => {
                  const lvl2Key = assetClassL1 + assetClassL2
                  const lvl3AssetCls = Object.keys(
                    batchedStrategies[assetClassL1][assetClassL2]
                  )
                  return (
                    <StrategyTile
                      key={lvl2Key}
                      containerClass='strategy-search__card'
                      titleProps={{
                        title: assetClassL2,
                        iconSize: 24,
                        iconStyle: { marginLeft: -10 },
                        titleClass: 'strategy-search__h3'
                      }}
                      collapseProps={{
                        controllOpen: this.state[lvl2Key],
                        onToggle: () => {
                          this.handleExpand('toggleSingle', lvl2Key)
                        }
                      }}>
                      {lvl3AssetCls.map((assetClassL3, index) => {
                        const lvl3Key =
                          assetClassL1 + assetClassL2 + assetClassL3
                        const lvl4AssetCls = Object.keys(
                          batchedStrategies[assetClassL1][assetClassL2][
                            assetClassL3
                          ]
                        )
                        return (
                          <StrategyTile
                            key={lvl3Key}
                            titleProps={{
                              title: assetClassL3,
                              titleHelper: () =>
                                this.renderPartialExpand(lvl3Key),
                              iconSize: 22,
                              iconStyle: { marginLeft: -4 },
                              titleClass: 'strategy-search__h4'
                            }}
                            collapseProps={{
                              controllOpen: this.state[lvl3Key],
                              onToggle: () => {
                                this.handleExpand('toggleSingle', lvl3Key)
                              }
                            }}
                            explorerLineProps={{
                              isLast: index === lvl3AssetCls.length - 1,
                              lineStyle: 'solid'
                            }}>
                            {lvl4AssetCls.map((assetClassL4, index) => {
                              const lvl4Key =
                                assetClassL1 +
                                assetClassL2 +
                                assetClassL3 +
                                assetClassL4
                              return (
                                <StrategyTile
                                  key={lvl4Key}
                                  containerClass='strategy-search__spacer--left'
                                  titleProps={{
                                    title: assetClassL4,
                                    iconStyle: { marginLeft: -2 }
                                  }}
                                  collapseProps={{
                                    controllOpen: this.state[lvl4Key],
                                    onToggle: () =>
                                      this.handleExpand('toggleSingle', lvl4Key)
                                  }}
                                  explorerLineProps={{
                                    isLast: index === lvl4AssetCls.length - 1,
                                    lineStyle: 'dotted'
                                  }}>
                                  <StrategyTable
                                    strategies={
                                      batchedStrategies[assetClassL1][
                                        assetClassL2
                                      ][assetClassL3][assetClassL4]
                                    }
                                  />
                                </StrategyTile>
                              )
                            })}
                          </StrategyTile>
                        )
                      })}
                    </StrategyTile>
                  )
                })}
              </div>
            )
          })}
          {!loading && <StrategyDisclaimer />}

          <StrategyToolTip strategyIds={strategyIds} />
        </InnerContainer>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => ({
  dGetIMStrategies: () => dispatch(getIMStrategies()),
  dGetInstitutions: () => dispatch(getInstitution())
})
const mapStateToProps = (store: GlobalState, { match }: any) => {
  const [batchedStrategies, assetClasses, strategyIds] = getStrategiesSelector(
    store
  )
  return {
    loading: store.strategySearch.loading,
    batchedStrategies,
    searchBarValue: store.strategySearch.search,
    assetClasses,
    strategyIds
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StrategySearch)
)
