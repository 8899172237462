import React, { FC, ChangeEvent } from 'react'
import classNames from 'classnames'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { updateSearchBarText } from '../../../../actions/strategySearch'
import searchIcon from '../../../assets/images/icons/search_dark-gray.png'
import xIcon from '../../../assets/images/icons/x_gray.png'

interface StrategySearchProps {
  searchBarValue: string
  dUpdateSearhBarText: (value: string) => void
}

const StrategySearch: FC<StrategySearchProps> = ({
  searchBarValue,
  dUpdateSearhBarText
}) => {
  const strategySearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length <= 100) {
      dUpdateSearhBarText(value)
    }
  }
  const clearButtonCls = classNames('strategy-search__input-clear', {
    'strategy-search__input-clear--show': searchBarValue?.length > 0
  })
  return (
    <div className='strategy-search__input-w'>
      <img
        className='strategy-search__input-icon'
        src={searchIcon}
        alt='input search icon'
      />
      <input
        className='strategy-search__input'
        type='text'
        value={searchBarValue}
        onChange={strategySearchChange}
        placeholder='Search by Strategy Name'
      />
      <img
        className={clearButtonCls}
        onClick={() => dUpdateSearhBarText('')}
        src={xIcon}
        alt='close icon'
      />
    </div>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<GlobalState>) => ({
  dUpdateSearhBarText: (value: string) => dispatch(updateSearchBarText(value))
})

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    searchBarValue: store.strategySearch.search
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StrategySearch)
