import React, { Fragment } from 'react'
import { connect, Dispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { dollarFormat, isObjEmpty } from '../../helpers'
import { GlobalState } from '../../../reducers'
import { HouseholdsState } from '../../../reducers/households'
import { addToast } from '../../../actions/toasts'
import {
  ClientAccountObj,
  ClientAccountObjState
} from '../../../objects/clientAccount'

import * as clientActions from '../../../actions/clientAccounts'
import * as householdActions from '../../../actions/households'
import { staticText } from '../../helpers/resources'

import BackToLink from '../../components/layout/headerBackToLink'
import InnerContainer from '../../components/layout/innerContainer'
import Tile from '../../components/layout/tile'
import Button from '../../components/button'
import ToggleSwitch from '../../components/toggleSwitch'
import Tooltip from '../../components/tooltip'
import AccountInformation from './accountInformationTile'
import AssetAllocation from './assetAllocation'
import InvestmentDetail from './investmentDetailTile'
import IntegrationInformation from './integrationInformation'
import OverrideAllocation from './editOverrideAllocation'
import { isEqual } from '../../helpers'
import { getUpdatedProps } from '../../helpers/clientAccounts'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg'
import overrideIcon from '../../assets/images/icons/png/ic_updates.png'
import { isEqual as compareValue } from 'lodash'

export interface EditClientAccountProps {
  componentReferrer?: string
  households: HouseholdsState
  householdFinId: string
  clientAccountId: string
  clientAccount: ClientAccountObj
  clientAccounts: ClientAccountObjState
  dispatch: Dispatch<GlobalState>
  match: any
}

interface EditClientAccountState {
  requiredFields: string[]
  errors: string[]
  showNoticeAlert: boolean
  includeGuideCenter: boolean
  pssSelected: boolean
  sendToPlanningSoftware: boolean
  showOverrideAllocation: boolean
  showInvestmentDetail: boolean
  showIntegrationInformation: boolean
  enableSave: boolean
  accountNumber: string
  accountNickname: string
  accountType: string
  accountYield: number
  balanceDate: string
  costBasis: number
  description: string
  guidebookClassification: string
  ownerIds: string[]
  registrationType: string
  productName: string
  isDeletedConnected: boolean
  textCustodian: string
  source: string
  status: string
  taxStatus: string
  totalValueManual: number
}

export class EditClientAccount extends React.Component<
  EditClientAccountProps,
  EditClientAccountState
> {
  constructor(props: EditClientAccountProps) {
    super(props)
    this.state = {
      showNoticeAlert: true,
      includeGuideCenter: false,
      pssSelected: false,
      sendToPlanningSoftware: false,
      showOverrideAllocation: false,
      showInvestmentDetail: true,
      showIntegrationInformation: true,
      enableSave: false,
      accountNickname: '',
      accountNumber: '',
      accountType: '',
      accountYield: 0,
      textCustodian: '',
      productName: '',
      isDeletedConnected: false,
      balanceDate: '',
      costBasis: 0,
      description: '',
      guidebookClassification: '',
      ownerIds: [],
      registrationType: '',
      source: '',
      status: '',
      taxStatus: '',
      totalValueManual: null,
      requiredFields: [
        'accountNickname',
        'accountNumber',
        'totalValue',
        'guidebookClassification',
        'totalValueManual'
      ],
      errors: []
    }
  }

  public updatedClientAccountProps = (nextProps: any) => {
    this.setState({ ...nextProps })
  }

  public componentDidUpdate(prevProps: any) {
    if (
      this?.props?.clientAccount &&
      !isEqual(prevProps?.clientAccount, this?.props?.clientAccount)
    ) {
      this.updatedClientAccountProps(getUpdatedProps(prevProps, this.props))
    }
  }

  public componentDidMount() {
    const { dispatch, clientAccount, householdFinId } = this.props
    if (clientAccount) {
      this.updatedClientAccountProps(this.props)
    }
    dispatch(clientActions.getClientAccounts(householdFinId))
  }

  public showOverrideAllocation = () => {
    this.setState({
      showOverrideAllocation: !this.state.showOverrideAllocation
    })
  }

  public toggleFeature = async (feature: keyof ClientAccountObj) => {
    const {
      dispatch,
      clientAccount,
      householdFinId,
      clientAccountId
    } = this.props

    await dispatch(
      clientActions.updateClientAccount(clientAccountId, householdFinId, {
        [feature]: !clientAccount[feature]
      })
    )
    if (feature === 'includeInGuidebook') {
      dispatch(householdActions.getIndividualHousehold(householdFinId))
    }
  }

  public accountInformationRightHeader = () => {
    const { clientAccount } = this.props
    let includeInGuidebook = false
    let includeInvestments = false
    let sendToPlanningSoftware = false
    if (clientAccount) {
      includeInGuidebook = clientAccount.includeInGuidebook
      includeInvestments = clientAccount.pssSelected
      sendToPlanningSoftware = clientAccount.sendToPlanningSoftware
    }
    return (
      <div className='ca-header-ctrl'>
        <ToggleSwitch
          active={includeInGuidebook}
          handleToggleActive={() => this.toggleFeature('includeInGuidebook')}
        />
        <div>Show in Net Worth</div>
        <ToggleSwitch
          active={includeInvestments}
          handleToggleActive={() => this.toggleFeature('pssSelected')}
        />
        <div>Show in Investments</div>
        <ToggleSwitch
          active={sendToPlanningSoftware}
          handleToggleActive={() =>
            this.toggleFeature('sendToPlanningSoftware')
          }
        />
        <div>Send to Planning Software</div>
      </div>
    )
  }

  public assetAllocationRightHeader = () => {
    const { clientAccount, componentReferrer } = this.props
    const totalAllocatedSum =
      clientAccount && clientAccount.totalValue ? clientAccount.totalValue : 0
    const referrer =
      componentReferrer === 'investments' ? 'investments' : 'networth'
    return (
      <div className='client-account-details__right-header'>
        <div className='client-account-details__right-header-button'>
          <Link
            to={`/households/${this.props.householdFinId}/clientAccount/${this.props.clientAccountId}/${referrer}/positions/`}>
            <Button header={true}>See Positions</Button>
          </Link>
        </div>
        <div className='client-account-details__right-header-button'>
          <Button header={true} onClick={this.showOverrideAllocation}>
            Override Allocation
          </Button>
        </div>
        <div className='client-account-details__right-header--value'>
          {clientAccount &&
          (clientAccount.category === 'Short-Term Liabilities' ||
            clientAccount.category === 'Long-Term Liabilities')
            ? `(${dollarFormat(totalAllocatedSum, 0)})`
            : dollarFormat(totalAllocatedSum, 0)}
        </div>
      </div>
    )
  }

  public changeNoticeToggle = () => {
    this.setState({ showNoticeAlert: !this.state.showNoticeAlert })
  }

  public backToLink = () => {
    const { componentReferrer } = this.props
    const referrer =
      componentReferrer === 'investments' ? 'investments' : 'networth'
    return (
      <BackToLink to={`/households/${this.props.householdFinId}/` + referrer}>
        Back to{' '}
        {componentReferrer === 'investments' ? 'Investments' : 'Net Worth'}
      </BackToLink>
    )
  }

  public checkForErrors = (
    fieldName: string,
    fieldValue: string | number | boolean
  ) => {
    const { requiredFields } = this.state
    const numberRegEx = new RegExp('^[0-9,]*$')
    let newErrors = this.state.errors
    let isValid = true

    if (typeof fieldValue === 'string') {
      if (
        requiredFields.indexOf(fieldName) >= 0 &&
        (!fieldValue || fieldValue.length === 0)
      ) {
        isValid = false
      }
      if (fieldName === 'totalValueManual') {
        isValid = numberRegEx.test(fieldValue)
      }
      if (fieldName === 'accountYield') {
        isValid = numberRegEx.test(fieldValue)
        const percentNumber = parseFloat(fieldValue)
        isValid = percentNumber % 1 === 0 || percentNumber <= 100
        if (fieldValue.length === 0) {
          isValid = true
        }
      }
    }

    if (isValid) {
      newErrors = newErrors.filter((item) => {
        return item !== fieldName
      })
    } else {
      newErrors.push(fieldName)
    }

    return newErrors.filter((item, index) => {
      return newErrors.indexOf(item) === index
    })
  }

  public inputSetter = (
    fieldName: string,
    fieldValue: string | number | boolean
  ) => {
    const newState = this.state
    const errors: string = 'errors'
    const enableSave: string = 'enableSave'
    const errorList = this.checkForErrors(fieldName, fieldValue)

    newState[fieldName] = fieldValue
    newState[errors] = errorList
    newState[enableSave] = !(errorList.length > 0)
    this.setState(newState)
  }

  public handleUpdateClientAccount = async () => {
    const {
      accountNickname,
      accountNumber,
      accountType,
      accountYield,
      balanceDate,
      costBasis,
      description,
      registrationType,
      taxStatus,
      totalValueManual,
      ownerIds,
      productName,
      textCustodian,
      isDeletedConnected,
      enableSave
    } = this.state
    const {
      clientAccountId,
      householdFinId,
      clientAccount: propsClientAccount
    } = this.props
    let { guidebookClassification } = this.state

    switch (guidebookClassification) {
      case 'Personal Assets':
        guidebookClassification = 'Other - Personal Assets'
        break
      case 'Business Assets':
        guidebookClassification = 'Other - Business Assets'
        break
      default:
    }
    // this function compares local account fields to redux account fields
    const verifyChangedFields = () => {
      const stateClientAccount = {
        accountNickname,
        accountNumber,
        accountType,
        accountYield,
        balanceDate,
        costBasis,
        description,
        guidebookClassification,
        isDeletedConnected,
        ownerIds,
        productName,
        registrationType,
        taxStatus,
        textCustodian,
        totalValueManual
      }
      return (Object.keys(stateClientAccount) as Array<
        keyof typeof stateClientAccount
      >).reduce<Record<string, unknown>>((changedFields, currentKey) => {
        if (
          !compareValue(
            stateClientAccount[currentKey],
            propsClientAccount[currentKey]
          )
        ) {
          changedFields[currentKey] = stateClientAccount[currentKey]
        }
        return changedFields
      }, {})
    }

    if (enableSave) {
      await this.props.dispatch(
        clientActions.updateClientAccount(
          clientAccountId,
          householdFinId,
          verifyChangedFields()
        )
      )
      this.props.dispatch(
        householdActions.getIndividualHousehold(householdFinId)
      )
      this.props.dispatch(
        addToast({
          icon: FileIcon,
          message: 'Account Updated.',
          backgroundColor: '#D9F2B6'
        })
      )
      this.setState({
        enableSave: false
      })
    }
  }

  public renderTile = (): JSX.Element => {
    const changesText = staticText.clientAccountChangeText
    return (
      <Tile
        headerStyle={{
          backgroundColor: '#E9E9E9',
          padding: '4px 15px'
        }}
        leftHeader={changesText}
        rightHeader={<CloseIcon onClick={this.changeNoticeToggle} />}>
        <Fragment />
      </Tile>
    )
  }

  public renderLeftHeader = () => {
    const { clientAccount } = this.props
    return clientAccount && clientAccount.allocationOverridden ? (
      <Fragment>
        ASSET ALLOCATION
        <Tooltip
          message='Account allocation has been overridden'
          width={120}
          position='bottom'
          multiLine={true}>
          <img
            src={overrideIcon}
            alt='Override Icon'
            className='inv-accounts__override-icon'
          />
        </Tooltip>
      </Fragment>
    ) : (
      'ASSET ALLOCATION'
    )
  }

  public render() {
    const { clientAccountId, householdFinId, clientAccount } = this.props
    const { showNoticeAlert, showOverrideAllocation, enableSave } = this.state
    const discreteAllocation =
      !clientAccount || isObjEmpty(clientAccount.discreteAllocation)
        ? {
            equity: 0,
            fixed: 0,
            cash: 0,
            alt: 0,
            unclassified: 100
          }
        : clientAccount.discreteAllocation
    return (
      <InnerContainer>
        {this.backToLink()}
        <div
          className='client-account-details-w'
          style={{ display: !showNoticeAlert ? 'none' : 'inline-block' }}>
          {this.renderTile()}
        </div>
        <div className='client-account-details-w'>
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA' }}
            leftHeader={'ACCOUNT INFORMATION'}
            rightHeader={this.accountInformationRightHeader()}
            headerBorder={true}>
            <AccountInformation
              {...this.state}
              clientAccountId={clientAccountId}
              householdFinId={householdFinId}
              inputSetter={this.inputSetter}
            />
          </Tile>
        </div>
        <div className='client-account-details-w'>
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA', padding: '8px 12px' }}
            leftHeader={this.renderLeftHeader()}
            rightHeader={this.assetAllocationRightHeader()}
            headerBorder={true}>
            <AssetAllocation
              householdFinId={householdFinId}
              clientAccountId={clientAccountId}
              clientAccount={clientAccount}
            />
          </Tile>
        </div>
        {clientAccount && (
          <>
            <InvestmentDetail
              {...this.state}
              householdFinId={householdFinId}
              clientAccount={clientAccount}
              clientAccountId={clientAccountId}
              showTile
              inputSetter={this.inputSetter}
            />

            <IntegrationInformation
              householdFinId={householdFinId}
              clientAccount={clientAccount}
              clientAccountId={clientAccountId}
              showTile
            />
          </>
        )}
        <div className='client-account-details-w'>
          <Tile
            headerStyle={{ backgroundColor: '#FAFAFA', padding: '8px 12px' }}
            headerBorder={true}>
            <div className='client-account-details'>
              <div className='client-account-details__inputs-buttons'>
                <div className='client-account-details__inputs__override-allocation-modal'>
                  <span
                    className={
                      enableSave ? 'btn btn__prime' : 'btn btn__default'
                    }
                    onClick={this.handleUpdateClientAccount}>
                    Save
                  </span>
                </div>
              </div>
            </div>
          </Tile>
        </div>
        {showOverrideAllocation ? (
          <OverrideAllocation
            householdFinId={householdFinId}
            clientAccountId={clientAccountId}
            closeModal={this.showOverrideAllocation}
            clientAccount={clientAccount}
            discreteAllocation={discreteAllocation}
            showTile
          />
        ) : null}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const clientAccount =
    store.clientAccount[match.params.householdFinId] &&
    store.clientAccount[match.params.householdFinId].clientAccounts[
      match.params.clientAccountId
    ]

  return {
    ...clientAccount,
    clientAccount,
    componentReferrer: match.params.componentReferrer,
    households: store.households,
    householdFinId: match.params.householdFinId,
    clientAccountId: match.params.clientAccountId
  }
}

export default withRouter(connect(mapStateToProps)(EditClientAccount))
