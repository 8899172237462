import React, { useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'

export interface StrategyToolTipProps {
  id: keyof typeof ESTooltipType
}

export enum ESTooltipType {
  sortFees = 'sortFees',
  operationalFee = 'operationalFee',
  expenseRatio = 'expenseRatio',
  managerFee = 'managerFee',
  notAvailable = 'notAvailable',
  showAll = 'showAllButton'
}

export const strategyTooltipText = {
  [ESTooltipType.expenseRatio]:
    'The expense ratio only incorporates the fee for the underlying holdings, which is dynamic in nature and would change whenever the strategy undergoes a model update changing the constituents/weightings of the portfolio. ',
  [ESTooltipType.managerFee]:
    'Associated fee for the sub-manager responsible for managing the strategy.',
  [ESTooltipType.operationalFee]:
    'Envestnet fee charged for the strategy including but not limited to gateway, platform and/or administration fees. Also known as sponsor fee as it relates to Envestnet.',
  [ESTooltipType.sortFees]: 'Combination of Manager fee and Operational fee.',
  [ESTooltipType.notAvailable]:
    'Not available for any retirement accounts or similar tax-qualified accounts (i.e., IRAs, tax-qualified plans, Keogh plans, ERISA Accounts, Coverdell ESAs.',
  [ESTooltipType.showAll]:
    'Click the button to view/hide strategies managed by this manager or by investment style shown.'
}
interface IStrategyTooltipProps {
  strategyIds: string[]
}

const StrategyToolTip = (props: IStrategyTooltipProps) => {
  const { strategyIds } = props

  const tooltipRefs = useRef([])
  // since we have dynamic content on the page we need to rebuild tooltips around it
  useEffect(() => {
    if (tooltipRefs.current && strategyIds.length > 0) ReactTooltip.rebuild()
  }, [JSON.stringify(strategyIds)])

  return (
    <>
      {Object.values(ESTooltipType).map((tooltipType, index) => (
        <ReactTooltip
          className='strategy-tooltip-body'
          key={tooltipType}
          type='dark'
          effect='solid'
          border={false}
          id={tooltipType}>
          {strategyTooltipText[tooltipType]}
        </ReactTooltip>
      ))}
    </>
  )
}

export default StrategyToolTip
