import React from 'react'
import StrategySearch from './strategySearch'
import Button from '../../../components/button'
import ucLogo from '../../../assets/images/logos/United-Capital-Logo-230x80.png'
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg'
import { ReactComponent as MinusIcon } from '../../../assets/images/icons/bts-minus.svg'

interface IStrategyHeaderProps {
  handleExpandAll: (mode: 'expandAll' | 'collapseAll') => void
}

const headerIconSize = 20
const headerButtonWidth = 134

const StrategyHeader = ({ handleExpandAll }: IStrategyHeaderProps) => {
  return (
    <div className='strategy-search__header-w'>
      <div className='strategy-search__header-inner'>
        <div className='strategy-search__header-top-w'>
          <img
            className='strategy-search__header-logo'
            src={ucLogo}
            alt='UC logo'
          />
        </div>

        <StrategySearch />
        <div>
          <Button
            style={{ width: headerButtonWidth }}
            header
            onClick={() => handleExpandAll('expandAll')}>
            <PlusIcon
              height={headerIconSize}
              width={headerIconSize}
              color='#245eb9'
            />
            <span className='vault__btn--text'>Expand All</span>
          </Button>
          <Button
            style={{ width: headerButtonWidth }}
            header
            onClick={() => handleExpandAll('collapseAll')}>
            <MinusIcon
              height={headerIconSize}
              width={headerIconSize}
              stroke='#245eb9'
              strokeWidth='1'
              color='#245eb9'
            />
            <span className='vault__btn--text'>Collapse All</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StrategyHeader
