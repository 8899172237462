import React, { Component } from 'react'
import StrategyItem from '../strategyItem'
import StrategySortingBar from './StrategySortingBar'
import { IMStrategiesObj } from '../../../../objects/strategies'
import { sortStrategies } from '../../../helpers/strategies'

export interface IStrategyTableProps {
  strategies: IMStrategiesObj[]
}

interface IStrategyTableState {
  expandedId: string
  sortBy: string
  ascending: boolean
}

/**
 *
 * Our default sort state is
 * sortBy : name, in an ascending order
 *
 */
class StrategyTable extends Component<
  IStrategyTableProps,
  IStrategyTableState
> {
  constructor(props: IStrategyTableProps) {
    super(props)
    this.state = {
      expandedId: null,
      sortBy: 'name',
      ascending: false
    }
  }

  /**
   *
   * @param id
   * set the id of the clicked strategy row
   */
  public setExpandedId = (id: string) => {
    const { expandedId } = this.state
    this.setState({
      expandedId: expandedId !== id ? id : null
    })
  }

  public sortFunc = (option: string, ascending: boolean) => {
    this.setState({
      sortBy: option,
      ascending
    })
  }

  public render = () => {
    const { strategies } = this.props
    const { expandedId, sortBy, ascending } = this.state

    if (!strategies?.length) return null

    const sortedStrategies = sortStrategies(strategies, sortBy, ascending)
    const lastIndex = sortedStrategies.length - 1

    return (
      <div className='strategy-search__table-main'>
        <div className='strategy-search__table-container'>
          <StrategySortingBar
            sortBy={sortBy}
            ascending={ascending}
            sortFunc={this.sortFunc}
          />
          {sortedStrategies.map((strategy: IMStrategiesObj, index: number) => {
            return (
              <StrategyItem
                key={strategy.id}
                strategy={strategy}
                expandedId={expandedId}
                expandFunc={this.setExpandedId}
                isLast={index === lastIndex}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default StrategyTable
