import React from 'react'
import classNames from 'classnames'

import {
  StrategyCollapsableItem,
  ExpolorerLines,
  CollapseDynamicIcon
} from '../strategyComponents'

export interface IStrategyTileProps {
  titleProps: {
    title: string
    titleHelper?: () => React.ReactElement
    iconSize?: number
    iconStyle?: React.CSSProperties
    titleClass?: string
  }

  collapseProps: {
    controllOpen: boolean
    onToggle: () => void
  }

  explorerLineProps?: {
    lineStyle?: 'dotted' | 'solid'
    isLast?: boolean
  }

  containerClass?: string
}
/**Composite component that encapsulates repeating code in StrategySearch */
const StrategyTile: React.FC<IStrategyTileProps> = ({
  titleProps: { title, titleHelper, iconSize, iconStyle, titleClass },
  collapseProps: { controllOpen, onToggle },
  explorerLineProps,
  children,
  containerClass
}) => {
  const cntCls = classNames('strategy-search__tile', containerClass)
  const titleCls = classNames('strategy-search__tile-title-text', titleClass)
  // explorerLineProps are optional
  const { lineStyle, isLast } = explorerLineProps || {}

  return (
    <div className={cntCls}>
      <StrategyCollapsableItem
        controllOpen={controllOpen}
        onToggle={onToggle}
        renderHeaderComponent={(open) => (
          <ExpolorerLines header isLast={isLast} lineStyle={lineStyle}>
            <div className='strategy-search__tile-title-container'>
              <CollapseDynamicIcon
                open={open}
                size={iconSize}
                iconStyles={{
                  marginRight: 4,
                  ...iconStyle
                }}
              />
              <div className={titleCls}>{title}</div>
              {titleHelper && titleHelper()}
            </div>
          </ExpolorerLines>
        )}>
        <ExpolorerLines body isLast={isLast} lineStyle={lineStyle}>
          {children}
        </ExpolorerLines>
      </StrategyCollapsableItem>
    </div>
  )
}

export default StrategyTile
