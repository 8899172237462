import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { dollarFormat } from '../../../helpers'

import { UserObj } from '../../../../objects/user'
import { IMStrategiesObj } from '../../../../objects/strategies'
import { InstitutionObj } from '../../../../objects/institution'

import arrowIconDown from '../../../assets/images/strategySearch/arrow_dropDown.png'
import alarmIcon from '../../../assets/images/strategySearch/ic-alarm.png'
import { ESTooltipType } from '../strategyTooltip'

interface StrategyDetailsProps {
  institution: InstitutionObj
  user: UserObj
  strategy: IMStrategiesObj
  expand: boolean
}

class StrategyDetails extends Component<StrategyDetailsProps> {
  public alertMessage = () => {
    const { strategy } = this.props
    return strategy.restrictedFromQualifiedAccounts ? (
      <img
        src={alarmIcon}
        style={{ width: 18, height: 18 }}
        data-tip={ESTooltipType.notAvailable}
        data-for={ESTooltipType.notAvailable}
      />
    ) : null
  }

  public feeDisplay = () => {
    const { institution, strategy } = this.props
    const { fees } = strategy

    const feeCss = institution?.isFinlifeInstitution
      ? 'strategy-search__col-item--flp-med strategy-search__item-display--fee'
      : 'strategy-search__col-item--short'

    return (
      <div
        className={`strategy-search__col-item strategy-search__item-display ${feeCss}`}>
        {(fees && typeof fees !== 'object' && `${fees}%`) || '0.00%'}
      </div>
    )
  }

  public render = () => {
    const {
      strategy: {
        name,
        strategyHighlight,
        investmentMinimum,
        assetClassLevelFive
      },
      expand
    } = this.props

    const arrowStyle = !expand
      ? 'strategy-search__item-expandIcon'
      : 'strategy-search__item-expandIcon strategy-search__item-expandIcon--rotate'

    return (
      <Fragment>
        <div className='strategy-search__col-item strategy-search__col-item--long strategy-search__item-display strategy-search__item-display--name'>
          <div>
            {name}
            {strategyHighlight && (
              <span className='strategy-search__item-gsexclusive'>
                {strategyHighlight}
              </span>
            )}
          </div>

          {this.alertMessage()}
        </div>

        <div className='strategy-search__col-item strategy-search__col-item--medium strategy-search__item-display'>
          {assetClassLevelFive || '-'}
        </div>
        <div className='strategy-search__col-item strategy-search__col-item--medium strategy-search__item-display'>
          {dollarFormat(investmentMinimum, 0, false) || '-'}
        </div>

        {this.feeDisplay()}
        <div
          className={`strategy-search__col-item strategy-search__item-display strategy-search__col-item--arrow`}>
          <img
            src={arrowIconDown}
            alt='Expand details'
            className={arrowStyle}
          />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const userId = store.user?.userId

  return {
    institution: store.institution,
    user: store.user?.users[userId]
  }
}

export default connect(mapStateToProps)(StrategyDetails)
