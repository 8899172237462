import React from 'react'
import classNames from 'classnames'

interface IExplorerLinesProps {
  isLast: boolean
  header?: boolean
  body?: boolean
  headerCls?: string
  bodyCls?: string
  width?: number
  lineStyle?: React.CSSProperties['borderStyle']
}

const ExpolorerLines: React.FC<IExplorerLinesProps> = ({
  isLast,
  header,
  body,
  children,
  headerCls,
  bodyCls,
  width = 10,
  lineStyle
}) => {
  if (!lineStyle) {
    return <>{children}</>
  } else if (header) {
    const headerContainerClasses = classNames(
      'explorer-lines__header',
      headerCls
    )
    const dynamicHeaderCls = classNames('explorer-lines__line-bottom', {
      'explorer-lines__line-bottom--active': !isLast
    })
    return (
      <div className={headerContainerClasses}>
        <div
          className='explorer-lines__line-container'
          style={{
            width
          }}>
          <div
            className='explorer-lines__line-left'
            style={{ borderStyle: lineStyle }}
          />
          <div
            className={dynamicHeaderCls}
            style={{ borderStyle: lineStyle }}
          />
        </div>
        {children}
      </div>
    )
  } else if (body) {
    const dynamicBodyCls = classNames('explorer-lines__body-line', bodyCls, {
      'explorer-lines__body-line--active': !isLast
    })
    return (
      <div className={dynamicBodyCls} style={{ borderStyle: lineStyle }}>
        {children}
      </div>
    )
  } else return null
}

export default ExpolorerLines
