import React from 'react'
import classNames from 'classnames'

export interface IStrategyCollapsableItemProps {
  renderHeaderComponent: (openState: boolean) => JSX.Element
  controllOpen?: boolean
  onToggle: () => void
}

const StrategyCollapsableItem: React.FC<IStrategyCollapsableItemProps> = ({
  controllOpen,
  renderHeaderComponent,
  onToggle,
  children
}) => {
  const collapsableContainerCls = classNames(
    'strategy-collapsable-item__inner-container',
    {
      'strategy-collapsable-item__inner-container--open': controllOpen
    }
  )
  return (
    <div className='strategy-collapsable-item'>
      <div className='strategy-collapsable-item__button' onClick={onToggle}>
        {renderHeaderComponent(controllOpen)}
      </div>
      <div className={collapsableContainerCls}>
        <div className='strategy-collapsable-item__inner-content'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default StrategyCollapsableItem
