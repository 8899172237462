import api from '../v3/helpers/api'

export const GET_IM_STRATEGIES = 'GET_IM_STRATEGIES'
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT'

export const getIMStrategies = () => {
  return {
    type: GET_IM_STRATEGIES,
    payload: api()
      .get('/strategies')
      .then((response) => {
        return response
      })
  }
}

export const updateSearchBarText = (searchText: string) => {
  return {
    type: UPDATE_SEARCH_TEXT,
    searchText
  }
}
