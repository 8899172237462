import React from 'react'
import { HouseholdObj } from '../../../../objects/household'
import {
  ClientAccountObj,
  classificationOptionList
} from '../../../../objects/clientAccount'
import Select from 'react-select'
import * as actions from '../../../../actions/clientAccounts'
import { connect, Dispatch } from 'react-redux'
import { GlobalState } from '../../../../reducers'
import { withRouter } from 'react-router'
import CheckBox from '../../../components/checkbox'
import { getSelectOptionByValue, dollarFormat } from '../../../helpers'
import HouseholdTextDisplay from '../../clientList/householdTextDisplay'

interface ClientAccountRowProps {
  household: HouseholdObj
  clientAccount: ClientAccountObj
  householdFinId: string
  dispatch: Dispatch<GlobalState>
}

class ClientAccountRow extends React.Component<ClientAccountRowProps> {
  public updateAccount = ({ ...args }) => {
    const { householdFinId, clientAccount, dispatch } = this.props
    dispatch(
      actions.updateClientAccount(clientAccount.id, householdFinId, {
        ...args
      })
    )
  }

  public updateOwner = (e: any) => {
    const { household } = this.props
    const ownerIds =
      e.value === 'Secondary'
        ? [household.secondaryContact, null]
        : e.value === 'Joint'
        ? [household.primaryContact, household.secondaryContact]
        : [household.primaryContact, null]
    this.updateAccount({ ownerIds })
  }

  public updateTaxStatus = (e: any) => {
    this.updateAccount({ taxStatus: e.value })
  }

  public updateClassification = (e: any) => {
    const guidebookClassification =
      e.value === 'Personal Assets' || e.value === 'Business Assets'
        ? `Other - ${e.value}`
        : e.value
    this.updateAccount({ guidebookClassification })
  }

  public updateSendToPlanningSoftware = (_data: string, checked: boolean) => {
    this.updateAccount({ sendToPlanningSoftware: checked })
  }

  public mapOwnerIds = (ownerIds: string[]): string => {
    const { household } = this.props
    let owner: string
    ownerIds &&
      household &&
      ownerIds.forEach((ownerId: string) => {
        if (household.primaryContact === ownerId) {
          owner = 'Primary'
        } else if (
          (owner === 'Primary' && household.secondaryContact === ownerId) ||
          (owner === 'Secondary' && household.primaryContact === ownerId)
        ) {
          owner = 'Joint'
        } else if (household.secondaryContact === ownerId) {
          owner = 'Secondary'
        }
      })
    return owner
  }

  public formatAccountClassification = () => {
    const { clientAccount } = this.props
    if (
      clientAccount.guidebookClassification === 'Other - Personal Assets' ||
      clientAccount.guidebookClassification === 'Other - Business Assets'
    ) {
      return clientAccount.guidebookClassification.replace('Other - ', '')
    } else return clientAccount.guidebookClassification
  }

  public render() {
    const { clientAccount } = this.props
    const ownerOptions = [
      { value: 'Primary', label: 'Primary' },
      { value: 'Secondary', label: 'Secondary' },
      { value: 'Joint', label: 'Joint' }
    ]
    const taxOptions = [
      { value: 'Taxable', label: 'Taxable' },
      { value: 'Tax Deferred', label: 'Tax Deferred' },
      { value: 'Tax Exempt', label: 'Tax Exempt' }
    ]
    const owner = this.mapOwnerIds(clientAccount.ownerIds)
    const classificationOptions = Object.keys(classificationOptionList).map(
      (key) => classificationOptionList[key]
    )
    return (
      <div className='financial-planning__content-row'>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--send'>
          <CheckBox
            checkedBorder={true}
            checked={Boolean(clientAccount.sendToPlanningSoftware)}
            onChange={this.updateSendToPlanningSoftware}
          />
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--acnumber'>
          {clientAccount.accountNumber}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--acnickname'>
          {clientAccount.accountNickname}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--owner '>
          <Select
            options={ownerOptions}
            value={getSelectOptionByValue(ownerOptions, owner)}
            onChange={this.updateOwner}
          />
        </div>
        <div className='financial-planning__content-row__item'>
          {clientAccount.accountType}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--birthdate'>
          <Select
            options={taxOptions}
            value={getSelectOptionByValue(taxOptions, clientAccount.taxStatus)}
            onChange={this.updateTaxStatus}
          />
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--regtype'>
          {clientAccount.registrationType}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--classification'>
          <Select
            options={classificationOptions}
            value={getSelectOptionByValue(
              classificationOptions,
              this.formatAccountClassification()
            )}
            onChange={this.updateClassification}
          />
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--cost'>
          {clientAccount.costBasis}
        </div>
        <div className='financial-planning__content-row__item financial-planning__content-row__item--totalvalue'>
          <HouseholdTextDisplay
            text={dollarFormat(clientAccount.totalValueManual, 0)}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: match.params.householdFinId,
    household: store.households[match.params.householdFinId]
  }
}

export default withRouter(connect(mapStateToProps)(ClientAccountRow))
