import React from 'react'

const StrategyDisclaimer = () => (
  <div className='strategy-search__main-disclaimer'>
    <p>For Financial Professional Use Only.</p>
    <br />
    <p>
      The information, data, analyses and opinions contained herein (1) include
      the confidential and proprietary information of United Capital, (2) may
      not be copied or redistributed, (3) do not constitute investment advice
      offered by United Capital, (4) are provided solely for informational
      purposes and therefore are not an offer to buy or sell a security, and (5)
      are not warranted to be correct, complete or accurate. Except as otherwise
      required by law, United Capital shall not be responsible for any trading
      decisions, damages or other losses resulting from, or related to, this
      information, data, analyses or opinions or their use.
    </p>
    <br />
    <p>
      Investing involves risk and investors should carefully consider their own
      investment objectives and never rely on any single chart, graph or
      marketing piece to make decisions. Equity investing involves market risk,
      including possible loss of principal. There are no investment strategies
      that guarantee a profit or protect against a loss. Past performance does
      not guarantee future results. United Capital provides sub-manager services
      to non-affiliated investment advisors, to help service their client's
      investment management needs. When managing assets as a sub-manager, United
      Capital relies solely on the client’s advisor to determine what the
      specific needs and circumstances of each client are and to choose the
      investment options that are appropriate to help meet each client’s needs.
      United Capital solely relies on information provided by the client’s
      advisor and does not independently verify any information provided.
    </p>
    <br />
    <p>
      ©{new Date().getFullYear()} United Capital Financial Advisors, LLC. All
      Rights Reserved.
    </p>
  </div>
)

export default StrategyDisclaimer
